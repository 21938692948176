<template>
  <backup-replication />
</template>

<script>
import BackupReplication from "../../components/solutions/data-center/BackupReplication.vue";
export default {
  name: "backup-replecation",
  components: { BackupReplication },
};
</script>
