<template>
  <LeftSection>
    <div class="info-desc col-lg-8">
      <h2 class="title-orange h1 mb-3 mt-5 pt-5">
        Backup Specialist Ejaf Technology
      </h2>
      <h4 class="my-3">
        Disaster doesn’t follow a schedule, it can happen anywhere, anytime.
      </h4>
      <p class="desc my-3">
        This is why our team analyzes the IT environment you are currently
        operating, in order to assess the Scooping maintenance and
        implementation of your business continuity and disaster recovery plan.
      </p>
    </div>
    <div class="info-img col-lg-4">
      <img
        class="w-100"
        src="../../../../assets/data-center/backup-and-replication/recovery-computer.png"
        alt="Explain Storage?"
      />
    </div>
  </LeftSection>
</template>

<script>
export default {
  name: "first-rep-section",
};
</script>
