<template>
  <RightSection>
    <div class="info-desc col-lg-8 pt-3">
      <h2 class="title-yellow h1">Cloud Computing</h2>
      <p class="desc">
        Now, what exactly is cloud computing? We provide services that enable
        our clients to deal, handle and reserve information to improve the
        manageability and decrease the supervision required eventually leading
        to better performance of applications.
      </p>
      <p class="desc">
        Data security is very crucial as it ensures that you can carry out your
        normal routine as usual even in times of adversity such as a natural
        calamity or a technical fault leading to power break down.
      </p>
    </div>
    <div class="info-img col-lg-4 mb-0 mb-sm-3 text-center">
      <img
        class="w-100"
        src="../../../../assets/data-center/cloud-computing/data-centers-and-system-solutionsimg-2.png"
        alt="Network-Attached Storage (NAS)"
      />
    </div>
  </RightSection>
</template>

<script>
export default {
  name: "second-comp-section",
};
</script>
