<template>
  <div class="modifying mt-5 text-center">
    <ul class="row">
      <li class="col-lg-4 mt-5">
        <i class="fa-regular fa-clock fs-1 icon"></i>
        <p class="mt-3">
          Companies <strong>lose about 300 hours annually</strong> through
          unplanned downtimes.
        </p>
      </li>
      <li class="col-lg-4 mt-5">
        <i class="fa-solid fa-gears fs-1 icon"></i>
        <p class="mt-3">
          <strong>48 percent</strong> of businesses (tech-related) experience
          performance issues daily.
        </p>
      </li>
      <li class="col-lg-4 mt-5">
        <i class="fa-solid fa-exclamation fs-1 icon"></i>
        <p class="mt-3">
          <strong>60 percent</strong> of companies said human error is the most
          common cause of downtime incidents.
        </p>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "third-rep-section",
};
</script>
