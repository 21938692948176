<template>
  <LeftSection>
    <div class="info-desc col-lg-8 pt-3">
      <h2 class="title-green h1">Benefits of Backup Solutions</h2>
      <p>The main services include and not limitd to:</p>
      <ul>
        <li>
          <p class="desc">Automatic protection and backup system.</p>
        </li>
        <li>
          <p class="desc">Decrease human-related risk factors.</p>
        </li>
        <li>
          <p class="desc">Enable quicker recovery of the data.</p>
        </li>
        <li>
          <p class="desc">
            Mitigate economical losses as a result of the loss of crucial
            commercial restoration.
          </p>
        </li>
        <li>
          <p class="desc">
            Simpler restoration of data in case of any system breakdown.
          </p>
        </li>
        <li>
          <p class="desc">Storage of data in real-time.</p>
        </li>
      </ul>
    </div>
    <div class="info-img col-lg-4 mb-0 mb-sm-3 text-center">
      <img
        class="w-100"
        src="../../../../assets/data-center/backup-and-replication/backup-recovery-ejaf-tech-canada.jpg"
        alt="Recovery & Backup Solutions"
      />
    </div>
  </LeftSection>
</template>

<script>
export default {
  name: "fivth-rep-section",
};
</script>
