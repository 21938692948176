<template>
  <RightSection>
    <div class="info-desc col-lg-8 pt-3">
      <h2 class="title-yellow h1">Recovery & Backup Solutions</h2>
      <p><strong>Are you ready to risk it all?</strong></p>
      <p class="desc">
        The answer is of course not!!! No business would want a downfall. It
        already takes a lot to establish a business and get it running, so no
        one would want to ruin it all.
      </p>
      <p class="desc">
        But don’t worry we won’t let that happen. Here at Ejaf, we offer you
        multiple services such as Network Attached Storage (NAS) to Storage Area
        Networks(SAN) that allows radical backups, disaster recovery and storage
        solutions. Our incredible IT infrastructure lends aid to both visual and
        physical environments and allows you to recover from any natural or
        technical dilemma.
      </p>
    </div>
    <div class="info-img col-lg-4 mb-0 mb-sm-3 text-center">
      <img
        class="w-100"
        src="../../../../assets/data-center/backup-and-replication/backup-archive.png"
        alt="Recovery & Backup Solutions"
      />
    </div>
  </RightSection>
</template>

<script>
export default {
  name: "fourth-rep-section",
};
</script>

<style></style>
